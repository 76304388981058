import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useInView } from "react-hook-inview"
import { graphql } from "gatsby"
import ScrollOut from "scroll-out"
import LoadIn from "./article-01/loadIn"
import MoreArticles from "../../../components/athena/moreArticlesJoy"

import ArticleHeader from "../../../components/athena/articleHeader"
import Contributors from "../../../components/athena/contributors"
import Layout, { athenaTheme } from "../../../components/layout"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"

import {
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  FacebookShareButton,
  FacebookIcon,
} from "react-share"

import {
  sequenceImagesReverse,
  sequenceImages,
  ScrollSequence,
} from "../../../components/athena/heroScroll"
import Egg from "../../../images/athena-04-resilience/01-joy-can-lead-us/egg.png"
import styles from "./joy-can-lead-us.module.scss"

import ExplorationAndPlay from "./article-01/joy-text-one"
import VulnerabilityHonesty from "./article-01/joy-text-two"
import JoyAndCreativeActs from "./article-01/joy-text-three"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
  headerPosition: styles.position,
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  //const coverImages = article.coverImages ? article.coverImages[0] : null

  let [heroFade, setHeroFade] = useState(true)
  useEffect(() => {
    setHeroFade(1)
    let timeoutId = setTimeout(() => {
      setHeroFade(2)
      timeoutId = setTimeout(() => {
        setHeroFade(3)
        timeoutId = setTimeout(() => {
          setHeroFade(4)
        }, 1750)
      }, 800)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [])

  let group = index => ({
    style: {
      transition: "opacity .6s ease-in-out",
      opacity: heroFade >= index ? 1 : 0.0125,
    },
  })

  const [sectionOneRef, sectionOneIsVisible] = useInView({
    rootMargin: "35px",
    threshold: 0,
  })
  const [sectionTwoRef, sectionTwoIsVisible] = useInView({
    rootMargin: "35px",
    threshold: 0,
  })
  const [sectionThreeRef, sectionThreeIsVisible] = useInView({
    rootMargin: "35px",
    threshold: 0,
  })

  useEffect(() => {
    let so = ScrollOut({
      offset: 5,
      cssProps: {
        viewportY: true,
      },
    })
    return so.teardown
  }, [])

  useEffect(() => {
    new ScrollSequence({
      container: ".first-sequence",
      scrollWith: ".first-container",
      images: sequenceImages,
      cover: false,
      playUntil: "scroll-out",
      starts: "in",
    })
    new ScrollSequence({
      container: ".second-sequence",
      scrollWith: ".second-container",
      images: sequenceImagesReverse,
      cover: false,
      playUntil: "scroll-out",
      starts: "out",
    })
    return
  }, [])

  return (
    <Layout seo={seo} mode="athena" theme={theme} {...group(1)}>
      <Helmet>
        <body className={`athena ${styles.body}`} />
      </Helmet>
      <article {...group(2)}>
        <ScrollProgressBar />
        <div className={styles.article}>
          <ArticleHeader
            issue={article.issue}
            title={article.title}
            //coverImages={coverImages}
          />

          <div className={styles.hero} {...group(3)}>
            <div className={styles.heroImage} data-scroll>
              <LoadIn />
            </div>
          </div>

          <div
            className={`${styles.scrollContainer} first-container`}
            data-scroll
          >
            <div className={`${styles.scrollSequenceContainer}`}>
              <div className={`${styles.firstSequence} first-sequence`}></div>
            </div>
          </div>

          <div className={`${styles.articleBodyTop} ${styles.bgTeal}`}>
            <div className="wrapper">
              <div className={styles.readingTime}>
                <p className={styles.reading}>5 min reading time</p>
                <p className={styles.readingMobile}>5 min read</p>
                <div className={styles.socialContainer}>
                  <p className={styles.share}>Share —</p>
                  <TwitterShareButton
                    url={
                      "https://zeusjones.com/athena/articles/joy-can-lead-us-to-more-sustainable-resilience/"
                    }
                    title={article.title}
                    className={styles.shareButtons}
                    via="zeusjones"
                  >
                    <TwitterIcon />
                  </TwitterShareButton>

                  <LinkedinShareButton
                    url={
                      "https://zeusjones.com/athena/articles/joy-can-lead-us-to-more-sustainable-resilience/"
                    }
                    title={article.title}
                    className={styles.shareButtons}
                    via="zeusjones"
                  >
                    <LinkedinIcon />
                  </LinkedinShareButton>

                  <FacebookShareButton
                    url={
                      "https://zeusjones.com/athena/articles/joy-can-lead-us-to-more-sustainable-resilience/"
                    }
                    title={article.title}
                    className={styles.shareButtons}
                    via="zeusjones"
                  >
                    <FacebookIcon />
                  </FacebookShareButton>
                </div>
              </div>
              <div className="columns is-desktop">
                <div className="column is-12-desktop">
                  <h2 className={`${styles.h2}`}>
                    <b>E</b>xisten<b>c</b>e <b>D</b>emands <b>r</b>esili<b>e</b>
                    n<b>c</b>e. Enduring thr<em>o</em>ugh <b>s</b>trugg<b>l</b>e
                    is a <b>p</b>ow<b>e</b>rful <b>t</b>ool, <b>b</b>ut f
                    <em>o</em>r most <em>o</em>f u<b>s</b> i<b>t</b> i<b>s</b>
                    n't <b>s</b>ustai<b>n</b>abl<b>e</b> i<b>n</b> <b>t</b>he l
                    <em>o</em>n<b>g</b> te<b>r</b>m.{" "}
                  </h2>
                </div>
              </div>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      People can only set aside their emotions, their desires,
                      and their health for so long before the expectation of
                      resilience becomes toxic. Maybe adversity does build
                      character. But it also builds frustration and apathy.
                      Given the current challenges to be overcome, we need all
                      the resilience we can get. But what's the point if we all
                      burn out in the process?
                    </p>
                    <p>
                      We need a concept of resilience that empowers us to
                      prioritize our wellness and agency, because our
                      communities deserve us at our best. We need to encourage
                      play and creativity, because progress only happens when we
                      have the freedom to imagine. But most importantly, we need
                      to make it our duty to pursue joy, not productivity,
                      because human flourishing can't be contained on a
                      spreadsheet.
                    </p>
                    <p>
                      The point is not simply to be more joyful. The point is to
                      take joy as our compass, a lens through which we assess
                      and act in the world around us, and begin a journey down a
                      path towards more sustainable resilience.
                    </p>
                    <p>We call it Joyful Resilience.</p>
                  </div>
                </div>
              </div>
              <div className="columns is-desktop">
                <div className="column is-12-desktop">
                  <h2 className={styles.h2}>
                    L<b>e</b>t'<b>s</b> Sta<b>r</b>t <b>w</b>ith w<b>h</b>at J
                    <em>o</em>yful <b>r</b>esili<b>e</b>n<b>c</b>e i<b>s</b>n'
                    <b>t</b>:
                  </h2>
                </div>
              </div>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      <strong>Joyful Resilience isn't efficient.</strong>
                      <br />
                      Efficiency is only achieved through control; controlling a
                      process, controlling movement, controlling emotion.
                      Efficiency asks humans to follow protocol, not their own
                      insights and abilities.
                    </p>

                    <p>
                      <strong>Joyful Resilience isnt about endurance.</strong>
                      <br />
                      Sometimes we have to struggle through adversity. But
                      sometimes we have options. Resilience works against us if
                      we see gritting our teeth as inherently valuable.
                    </p>

                    <p>
                      <strong>
                        Joyful Resilience doesn't foster optimal growth.
                      </strong>
                      <br />
                      Capitalism conditions us to seek constant growth and we
                      feel behind the curve when we don't. Yet this is
                      unrealistic for both revenue and our personal development.
                    </p>

                    <p>
                      <strong>
                        Joyful Resilience can't produce constant happiness.
                      </strong>
                      <br />
                      Anger, sorrow, confusion, and pain will always be part of
                      life. Ideals that ask us to minimize our emotional
                      experience for the sake of production make us less human.
                    </p>
                  </div>
                </div>
              </div>
              <div className="columns is-desktop">
                <div className="column is-12-desktop">
                  <h2 className={styles.h2}>
                    W<b>h</b>at J<em>o</em>yful <br />
                    <b>R</b>esili<b>e</b>n<b>c</b>e i<b>s</b>:
                  </h2>
                </div>
              </div>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      <strong>Joyful Resilience is exploratory.</strong>
                      <br />
                      Solving problems requires imagination, a quality we only
                      develop if we use it. Unstructured play gives us
                      opportunities to experiment and stretch our thinking in
                      ways that make us more flexible under stress.
                    </p>

                    <p>
                      <strong>Joyful Resilience is about agency.</strong>
                      <br />
                      We all have agency, but it takes courage and honesty to
                      express it. Radical honesty makes us vulnerable, but also
                      reinforces our agency amid systems that usually fail to
                      consider it.
                    </p>

                    <p>
                      <strong>Joyful Resilience does foster divergence.</strong>
                      <br />
                      Nothing is easier to maintain than the status quo, but our
                      world only gets better when we allow space for change. The
                      freedom to fail sparks innovative ideas, and fun, creative
                      acts become necessary acts of resilience and divergence in
                      a world dominated by inhuman algorithms.
                    </p>

                    <p>
                      <strong>
                        Joyful Resilience can liberate us to be who we really
                        are.
                      </strong>
                      <br />
                      Joy is one of our most potent yet underutilized resources.
                      When we are joyful we don't just feel good, we radiate
                      positivity and embody the most pure expressions of our
                      human selves.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.scrollContainerTwo} second-container`}>
            <div className={styles.animation}></div>
            <div
              data-scroll="out"
              className={`${styles.scrollSequenceContainerTwo}`}
            >
              <div className={`${styles.secondSequence} second-sequence`}></div>
              <img src={Egg} className={styles.egg} alt="" />
            </div>
          </div>
        </div>

        <div className={`${styles.articleBodyBottom} ${styles.bgTeal}`}>
          <div className="wrapper">
            <div className="columns is-tablet is-centered">
              <div className="column is-10-tablet is-8-desktop">
                <div className="user-content-athena">
                  <p>
                    Joyful Resilience may sound light-hearted, but creating a
                    culture to reflect it involves complex choices: both
                    personal and institutional.
                  </p>
                  <p>
                    As individuals, we can seek opportunities to explore without
                    expectation and have the courage to reject toxic demands on
                    our energy. But those choices are sometimes only possible
                    when businesses, schools, and other powerful institutions
                    open and defend spaces that normalize them.
                  </p>
                  <p>
                    Our current mantra of endurance-at-all-costs will not upend
                    itself. It will take fervent expressions of personal agency
                    and sustained effort from leaders willing to prioritize
                    human joy over productivity.
                  </p>
                  <p>
                    Aligning culture with human flourishing will take
                    creativity, but the seeds of it already exist. Below are a
                    few starting points to help us think through what joyful
                    resilience can be.
                  </p>
                </div>
              </div>
            </div>
            <div className="columns is-desktop">
              <div className="column is-12-desktop">
                <div className={styles.shareQuote}>
                  <h2 className={styles.h2}>
                    <span>
                      <b>G</b>iv<b>e</b>n
                    </span>
                    <span>
                      th<b>e</b>
                    </span>
                    <span>
                      <b>c</b>urr<b>e</b>nt
                    </span>
                    <span>
                      chall<b>e</b>nge<b>s</b>
                    </span>
                    <span>to</span>
                    <span>be</span>
                    <span>
                      <em>o</em>vercome,
                    </span>
                    <span>we</span>
                    <span>
                      <b>n</b>eed
                    </span>
                    <span>
                      <b>a</b>ll
                    </span>
                    <span>
                      <b>t</b>he
                    </span>
                    <span>
                      <b>r</b>esili<b>e</b>n<b>c</b>e
                    </span>
                    <span>we</span>
                    <span>
                      <b>c</b>an
                    </span>
                    <span>
                      g<b>e</b>t.
                    </span>
                    <span>
                      <b>B</b>ut
                    </span>
                    <span>
                      <b>w</b>hat's
                    </span>
                    <span>the</span>
                    <span>point</span>
                    <span>
                      i<b>f</b>
                    </span>
                    <span>we</span>
                    <span>
                      <b>a</b>ll
                    </span>
                    <span>
                      <b>b</b>u<b>r</b>n
                    </span>
                    <span>
                      <em>o</em>ut
                    </span>
                    <span>in</span>
                    <span>
                      <b>t</b>he
                    </span>
                    <span>
                      pr<em>o</em>
                      <b>c</b>ess?
                    </span>
                    &nbsp;
                    <TwitterShareButton
                      url={
                        "https://zeusjones.com/athena/articles/joy-can-lead-us-to-more-sustainable-resilience/"
                      }
                      title="Given the current challenges to be overcome, we need all the resilience we can get. But what's the point if we all burnout in the process?"
                      className={styles.articleShareButton}
                      via="zeusjones"
                    >
                      <span>
                        Tweet <TwitterIcon />
                      </span>
                    </TwitterShareButton>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper" ref={sectionOneRef}>
            <div className={styles.stickyColumns}>
              <div className={styles.stickyColumnLeft}>
                {sectionOneIsVisible && <ExplorationAndPlay />}
              </div>
              <div className={styles.stickyColumnRight}>
                <div className="user-content-athena">
                  <p>
                    Play is central for positive educational and developmental
                    outcomes.{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://unesdoc.unesco.org/ark:/48223/pf0000370734"
                    >
                      {" "}
                      UNESCO
                    </a>{" "}
                    directly links play to the development of resilience,
                    cognitive flexibility, problem solving, and healthy coping
                    skills for children in poverty. These benefits are echoed by
                    Unicef and the Lego Foundation in a joint report discussing
                    the importance of “playful learning” for developing one's
                    sense of agency during early childhood.
                  </p>
                  <p>
                    But play isn't just for kids. Adults stand to gain enormous
                    benefits from expectation-free exploration.{" "}
                    <a
                      href="https://www.bbc.com/future/article/20220127-why-adults-should-embrace-their-playfulness"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Psychologist René Proyer
                    </a>{" "}
                    believes adults should embrace play “in the same way that
                    you might take part in meditation or exercise, playfulness
                    should be viewed as a skill that can be developed, harnessed
                    and used for mindfulness.”
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper" ref={sectionTwoRef}>
            <div className={styles.stickyColumns}>
              <div className={styles.stickyColumnLeft}>
                {sectionTwoIsVisible && <VulnerabilityHonesty />}
              </div>
              <div className={styles.stickyColumnRight}>
                <div className="user-content-athena">
                  <p>
                    Simone Biles showed us how powerful it can be to embrace
                    vulnerability when she withdrew from five of her six
                    gymnastic events at the Tokyo Olympic Games. Radically
                    honest about her mental health, Biles was resolute in her
                    choice to prioritize well-being over competition. Her
                    decision shook the sports world and catapulted issues of
                    mental health in athletics to the headlines, with hundreds
                    of athletes from around the globe voicing their support.
                  </p>
                  <p>
                    {" "}
                    <a
                      href="https://www.nytimes.com/2021/07/24/sports/olympics/simone-biles-gymnastics.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Before arriving at the Tokyo games
                    </a>{" "}
                    , Biles said that the happiest moment of her career was,
                    “Honestly, probably my time off.” {" "}
                    <a
                      href="https://www.elle.com/culture/celebrities/a37980051/simone-biles-reflects-on-tokyo-withdrawal/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      After her withdrawal she recalled,
                    </a>{" "}
                    “Having the courage to take care & put myself first,” was
                    “the first time I felt human. Besides Simone Biles, I was
                    Simone.” Biles is now partnered with Cerebral, an online
                    mental-healthcare provider, as their Chief Impact Officer.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper" ref={sectionThreeRef}>
            <div className={styles.stickyColumns}>
              <div className={styles.stickyColumnLeft}>
                {sectionThreeIsVisible && <JoyAndCreativeActs />}
              </div>
              <div className={styles.stickyColumnRight}>
                <div className="user-content-athena">
                  <p>
                    The expression of Black joy is explored and celebrated not
                    just as a condition of flourishing, but as a necessary
                    source of resistance and healing. “To resist the
                    omnipresent, intrusive and pervasive nature of white
                    supremacy,"{" "}
                    <a
                      href="https://www.vogue.co.uk/arts-and-lifestyle/article/what-is-black-joy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      says writer and host Chanté Joseph,
                    </a>{" "}
                    "we must allow ourselves to be rebelliously joyous.” Through
                    purposefully amplifying Black joy, those bearing the burden
                    of racism find laughter and solidarity amid violence and a
                    new opportunity to understand oppressive forces by using joy
                    as a lens to reimagine a world without them.
                  </p>
                  <p>
                    Designer and author Ingrid Fetell Lee focuses her work on
                    embracing joy by creatively designing the made environment
                    that surrounds us. On her website,{" "}
                    <a
                      href="https://aestheticsofjoy.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Aesthetics of Joy,
                    </a>{" "}
                    she argues that creating spaces with whimsical colors,
                    patterns, and shapes pulls us away from our rigid
                    expectations of utility and makes for a more joyful
                    existence. Life's stressors are all around us, but{" "}
                    <a
                      href="https://www.aspenideas.org/articles/joy-is-a-form-of-resilience"
                      rel="noopener noreferrer"
                    >
                      according to Lee,
                    </a>{" "}
                    small instances of creativity breed a joyful grounding
                    effect, making us more resilient when we're under pressure.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <div className="columns is-12-desktop">
              <div className="column is-12-desktop">
                <h2 className={styles.h2}>
                  Loo<b>k</b>in<b>g</b> F<b>o</b>r<b>w</b>ar<b>d</b>
                </h2>
              </div>
            </div>
            <div className="columns is-tablet is-centered">
              <div className="column is-10-tablet is-8-desktop">
                <div className="user-content-athena">
                  <p>
                    Joyful Resilience doesn't just fortify individuals. It
                    builds more flexible businesses, supportive communities, and
                    open-minded cultures. Join us in this issue of Athena Jones
                    as we explore, experience, and express the possibilities
                    that open to all of us when joy and resilience collide.
                  </p>
                  <p>
                    Experimenting with collective play through sound, building a
                    vocabulary of joyful resilience, and rethinking a resilient
                    workplace are just a few of the topics we're thinking about.
                    Stay tuned.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contributors
            zeus={article.zeusContributors}
            artwork={article.otherContributorsArtwork}
          />
          <MoreArticles
            className={styles.moreFrom}
            id={article.id}
            issue={article.issue[0].id}
          />
        </div>
        <PreFooter />
      </article>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributorsArtwork {
            ... on Craft_otherContributorsArtwork_BlockType {
              artistName
              artistWebsite
            }
          }
        }
      }
    }
  }
`
