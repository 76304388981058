import React, { useState, useEffect } from "react"

import { useMedia } from "../../../../hooks"

import styles from "../joy-can-lead-us.module.scss"

import img00 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/00.jpg"
import img01 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/01.jpg"
import img02 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/02.jpg"
import img03 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/03.jpg"
import img04 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/04.jpg"
import img05 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/05.jpg"
import img06 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/06.jpg"
import img07 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/07.jpg"
import img08 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/08.jpg"
import img09 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/09.jpg"
import img10 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/10.jpg"
import img11 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/11.jpg"
import img12 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/12.jpg"
import img13 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/13.jpg"
import img14 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/14.jpg"
import img15 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/15.jpg"
import img16 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/16.jpg"
import img17 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/17.jpg"
import img18 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/18.jpg"
import img19 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/19.jpg"
import img20 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/20.jpg"
import img21 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/21.jpg"
import img22 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/22.jpg"
import img23 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/23.jpg"
import img24 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/24.jpg"
import img25 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/25.jpg"
import img26 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/26.jpg"
import img27 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/27.jpg"
import img28 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/28.jpg"
import img29 from "../../../../images/athena-04-resilience/01-joy-can-lead-us/loadin/29.jpg"

const LoadIn = () => {
  const LoadInImages = [
    img00,
    img01,
    img02,
    img03,
    img04,
    img05,
    img06,
    img07,
    img08,
    img09,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
  ]
  let media = useMedia()
  let desktop = media(">tablet")

  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      LoadInImages.forEach((_, i) =>
        setTimeout(() => setCurrentImageIndex(i), (i + 1) * 50)
      )
    }, 1500)
  }, [])

  return (
    <div className={styles.heroImage}>
      {LoadInImages.map((image, index) => {
        const mult = index * 2
        const desktopTop = 265 + mult
        const tabletTop = 285 + mult
        const desktopTopStyles = {
          top: desktopTop,
        }
        const key = index + 1
        const tabletTopStyles = {
          top: tabletTop,
        }
        return (
          <img
            className={`${styles.img} ${
              index === currentImageIndex ? `${styles.active}` : ""
            }`}
            src={image}
            alt="HeroImage"
            key={key}
            style={desktop ? desktopTopStyles : tabletTopStyles}
          />
        )
      })}
    </div>
  )
}

export default LoadIn
